/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const ListingWizardState = {
    NotSet: 'NotSet',
    InProgress_VehicleDetails_ManufacturerAndModel: 'InProgress_VehicleDetails_ManufacturerAndModel',
    InProgress_VehicleDetails_TransmissionAndEngine: 'InProgress_VehicleDetails_TransmissionAndEngine',
    InProgress_VehicleDetails_OtherModel: 'InProgress_VehicleDetails_OtherModel',
    InProgress_VehicleDetails_Amenities: 'InProgress_VehicleDetails_Amenities',
    InProgress_PickUpLocation: 'InProgress_PickUpLocation',
    InProgress_RulesAndPolicies: 'InProgress_RulesAndPolicies',
    InProgress_Pricing_Prices: 'InProgress_Pricing_Prices',
    InProgress_Pictures: 'InProgress_Pictures',
    InProgress_Summary_Recap: 'InProgress_Summary_Recap',
    InProgress_Summary_Agreements: 'InProgress_Summary_Agreements',
    WaitingForApproval: 'WaitingForApproval',
    Approved: 'Approved',
    Declined: 'Declined',
    OnHold: 'OnHold',
    Deleted: 'Deleted'
} as const;

export type ListingWizardState = typeof ListingWizardState[keyof typeof ListingWizardState];



