/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const CountryCode = {
    NotSet: 'NotSet',
    Afghanistan: 'Afghanistan',
    Algeria: 'Algeria',
    Azerbaijan: 'Azerbaijan',
    Albania: 'Albania',
    Armenia: 'Armenia',
    Argentina: 'Argentina',
    Australia: 'Australia',
    Austria: 'Austria',
    Bahrain: 'Bahrain',
    Botswana: 'Botswana',
    Belgium: 'Belgium',
    Bangladesh: 'Bangladesh',
    Belize: 'Belize',
    BosniaHerzegovina: 'BosniaHerzegovina',
    Bolivia: 'Bolivia',
    Myanmar: 'Myanmar',
    Belarus: 'Belarus',
    Brazil: 'Brazil',
    Bhutan: 'Bhutan',
    Bulgaria: 'Bulgaria',
    Brunei: 'Brunei',
    Canada: 'Canada',
    Cambodia: 'Cambodia',
    SriLanka: 'SriLanka',
    Congo: 'Congo',
    China: 'China',
    Chile: 'Chile',
    Cameroon: 'Cameroon',
    Colombia: 'Colombia',
    CostaRica: 'CostaRica',
    Cuba: 'Cuba',
    Cyprus: 'Cyprus',
    Denmark: 'Denmark',
    DominicanRepublic: 'DominicanRepublic',
    Ecuador: 'Ecuador',
    Egypt: 'Egypt',
    Ireland: 'Ireland',
    Estonia: 'Estonia',
    Eritrea: 'Eritrea',
    ElSalvador: 'ElSalvador',
    Ethiopia: 'Ethiopia',
    Czechia: 'Czechia',
    Finland: 'Finland',
    FaroeIslands: 'FaroeIslands',
    France: 'France',
    Georgia: 'Georgia',
    Greenland: 'Greenland',
    Germany: 'Germany',
    Greece: 'Greece',
    Guatemala: 'Guatemala',
    Haiti: 'Haiti',
    HongKongSAR: 'HongKongSAR',
    Honduras: 'Honduras',
    Croatia: 'Croatia',
    Hungary: 'Hungary',
    Iceland: 'Iceland',
    Indonesia: 'Indonesia',
    India: 'India',
    Iran: 'Iran',
    Israel: 'Israel',
    Italy: 'Italy',
    IvoryCoast: 'IvoryCoast',
    Iraq: 'Iraq',
    Japan: 'Japan',
    Jamaica: 'Jamaica',
    Jordan: 'Jordan',
    Kenya: 'Kenya',
    Kyrgyzstan: 'Kyrgyzstan',
    Korea: 'Korea',
    Kuwait: 'Kuwait',
    Kazakhstan: 'Kazakhstan',
    Laos: 'Laos',
    Lebanon: 'Lebanon',
    Latvia: 'Latvia',
    Lithuania: 'Lithuania',
    Slovakia: 'Slovakia',
    Liechtenstein: 'Liechtenstein',
    Luxembourg: 'Luxembourg',
    Libya: 'Libya',
    MacaoSAR: 'MacaoSAR',
    Moldova: 'Moldova',
    Mongolia: 'Mongolia',
    Mali: 'Mali',
    Monaco: 'Monaco',
    Morocco: 'Morocco',
    Malta: 'Malta',
    Oman: 'Oman',
    Maldives: 'Maldives',
    Mexico: 'Mexico',
    Malaysia: 'Malaysia',
    Nigeria: 'Nigeria',
    Netherlands: 'Netherlands',
    Norway: 'Norway',
    Nepal: 'Nepal',
    Nicaragua: 'Nicaragua',
    NewZealand: 'NewZealand',
    Paraguay: 'Paraguay',
    Peru: 'Peru',
    Pakistan: 'Pakistan',
    Poland: 'Poland',
    Panama: 'Panama',
    Portugal: 'Portugal',
    Qatar: 'Qatar',
    Runion: 'Réunion',
    Romania: 'Romania',
    Philippines: 'Philippines',
    PuertoRico: 'PuertoRico',
    Russia: 'Russia',
    Rwanda: 'Rwanda',
    SaudiArabia: 'SaudiArabia',
    SouthAfrica: 'SouthAfrica',
    Senegal: 'Senegal',
    Slovenia: 'Slovenia',
    Singapore: 'Singapore',
    Somalia: 'Somalia',
    Spain: 'Spain',
    Sweden: 'Sweden',
    Syria: 'Syria',
    Switzerland: 'Switzerland',
    UnitedArabEmirates: 'UnitedArabEmirates',
    TrinidadTobago: 'TrinidadTobago',
    Thailand: 'Thailand',
    Tajikistan: 'Tajikistan',
    Tunisia: 'Tunisia',
    Turkey: 'Turkey',
    Taiwan: 'Taiwan',
    Turkmenistan: 'Turkmenistan',
    Ukraine: 'Ukraine',
    UnitedKingdom: 'UnitedKingdom',
    UnitedStates: 'UnitedStates',
    Uruguay: 'Uruguay',
    Uzbekistan: 'Uzbekistan',
    Venezuela: 'Venezuela',
    Vietnam: 'Vietnam',
    Yemen: 'Yemen',
    Zimbabwe: 'Zimbabwe',
    Montenegro: 'Montenegro',
    Serbia: 'Serbia'
} as const;

export type CountryCode = typeof CountryCode[keyof typeof CountryCode];



