/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateOperatorFromListingWizardCommand } from '../models';
// @ts-ignore
import { GetOperatorListingWizardsQueryResultPaginatedResult } from '../models';
// @ts-ignore
import { ListingWizardPictureType } from '../models';
// @ts-ignore
import { ListingWizardQueryResult } from '../models';
// @ts-ignore
import { ListingWizardRecommendedBasePriceQueryResult } from '../models';
// @ts-ignore
import { ListingWizardRecommendedOtherPricesQueryResult } from '../models';
// @ts-ignore
import { ListingWizardState } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { UpdateListingWizardAgreementsAndSubmitCommand } from '../models';
// @ts-ignore
import { UpdateListingWizardPickUpLocationCommand } from '../models';
// @ts-ignore
import { UpdateListingWizardPricingBasePriceCommand } from '../models';
// @ts-ignore
import { UpdateListingWizardRulesAndPoliciesCommand } from '../models';
// @ts-ignore
import { UpdateListingWizardSummaryNotesCommand } from '../models';
// @ts-ignore
import { UpdateListingWizardVehicleDetailsAmenitiesCommand } from '../models';
// @ts-ignore
import { UpdateListingWizardVehicleDetailsManufacturerAndModelCommand } from '../models';
// @ts-ignore
import { UpdateListingWizardVehicleDetailsManufacturerAndModelResponse } from '../models';
// @ts-ignore
import { UpdateListingWizardVehicleDetailsOtherModelCommand } from '../models';
// @ts-ignore
import { UpdateListingWizardVehicleDetailsTransmissionAndEngineCommand } from '../models';
// @ts-ignore
import { UpdateListingWizardVehicleDetailsTransmissionAndEngineResponse } from '../models';
/**
 * ListingWizardOperatorApi - axios parameter creator
 * @export
 */
export const ListingWizardOperatorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [manufacturerGuid] 
         * @param {string} [modelGuid] 
         * @param {number} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsRecommendedBasePriceGet: async (manufacturerGuid?: string, modelGuid?: string, year?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/operator/listings/recommended-base-price`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (manufacturerGuid !== undefined) {
                localVarQueryParameter['manufacturerGuid'] = manufacturerGuid;
            }

            if (modelGuid !== undefined) {
                localVarQueryParameter['modelGuid'] = modelGuid;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [manufacturerGuid] 
         * @param {string} [modelGuid] 
         * @param {number} [year] 
         * @param {number} [basePrice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsRecommendedOtherPricesGet: async (manufacturerGuid?: string, modelGuid?: string, year?: number, basePrice?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/operator/listings/recommended-other-prices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (manufacturerGuid !== undefined) {
                localVarQueryParameter['manufacturerGuid'] = manufacturerGuid;
            }

            if (modelGuid !== undefined) {
                localVarQueryParameter['modelGuid'] = modelGuid;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (basePrice !== undefined) {
                localVarQueryParameter['basePrice'] = basePrice;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageOffset] The offset from where the pagination starts.
         * @param {number} [pageSize] The size of page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsWizardsDeclinedGet: async (pageOffset?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/operator/listings/wizards/declined`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageOffset !== undefined) {
                localVarQueryParameter['PageOffset'] = pageOffset;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageOffset] The offset from where the pagination starts.
         * @param {number} [pageSize] The size of page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsWizardsGet: async (pageOffset?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/operator/listings/wizards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageOffset !== undefined) {
                localVarQueryParameter['PageOffset'] = pageOffset;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageOffset] The offset from where the pagination starts.
         * @param {number} [pageSize] The size of page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsWizardsInprogressGet: async (pageOffset?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/operator/listings/wizards/inprogress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageOffset !== undefined) {
                localVarQueryParameter['PageOffset'] = pageOffset;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the HTML template of the mediation contract.
         * @param {string} listingWizardGuid 
         * @param {string} [accessToken] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        apiV1OperatorListingsWizardsListingWizardGuidMediationContractHtmlGet: async (listingWizardGuid: string, accessToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listingWizardGuid' is not null or undefined
            assertParamExists('apiV1OperatorListingsWizardsListingWizardGuidMediationContractHtmlGet', 'listingWizardGuid', listingWizardGuid)
            const localVarPath = `/api/v1/operator/listings/wizards/{listingWizardGuid}/mediation-contract/html`
                .replace(`{${"listingWizardGuid"}}`, encodeURIComponent(String(listingWizardGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (accessToken !== undefined) {
                localVarQueryParameter['accessToken'] = accessToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} listingWizardId 
         * @param {ListingWizardState} [currentUiState] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsWizardsListingWizardIdGet: async (listingWizardId: number, currentUiState?: ListingWizardState, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listingWizardId' is not null or undefined
            assertParamExists('apiV1OperatorListingsWizardsListingWizardIdGet', 'listingWizardId', listingWizardId)
            const localVarPath = `/api/v1/operator/listings/wizards/{listingWizardId}`
                .replace(`{${"listingWizardId"}}`, encodeURIComponent(String(listingWizardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (currentUiState !== undefined) {
                localVarQueryParameter['currentUiState'] = currentUiState;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the PDF file of mediation contract.
         * @param {number} listingWizardId The listing wizard identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsWizardsListingWizardIdMediationContractPdfGet: async (listingWizardId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listingWizardId' is not null or undefined
            assertParamExists('apiV1OperatorListingsWizardsListingWizardIdMediationContractPdfGet', 'listingWizardId', listingWizardId)
            const localVarPath = `/api/v1/operator/listings/wizards/{listingWizardId}/mediation-contract/pdf`
                .replace(`{${"listingWizardId"}}`, encodeURIComponent(String(listingWizardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} listingWizardId 
         * @param {UpdateListingWizardPickUpLocationCommand} [updateListingWizardPickUpLocationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsWizardsListingWizardIdPickUpLocationPut: async (listingWizardId: number, updateListingWizardPickUpLocationCommand?: UpdateListingWizardPickUpLocationCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listingWizardId' is not null or undefined
            assertParamExists('apiV1OperatorListingsWizardsListingWizardIdPickUpLocationPut', 'listingWizardId', listingWizardId)
            const localVarPath = `/api/v1/operator/listings/wizards/{listingWizardId}/pick-up-location`
                .replace(`{${"listingWizardId"}}`, encodeURIComponent(String(listingWizardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateListingWizardPickUpLocationCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} listingWizardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsWizardsListingWizardIdPicturesConfirmPut: async (listingWizardId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listingWizardId' is not null or undefined
            assertParamExists('apiV1OperatorListingsWizardsListingWizardIdPicturesConfirmPut', 'listingWizardId', listingWizardId)
            const localVarPath = `/api/v1/operator/listings/wizards/{listingWizardId}/pictures/confirm`
                .replace(`{${"listingWizardId"}}`, encodeURIComponent(String(listingWizardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} listingWizardId 
         * @param {string} [pictureGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsWizardsListingWizardIdPicturesDelete: async (listingWizardId: number, pictureGuid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listingWizardId' is not null or undefined
            assertParamExists('apiV1OperatorListingsWizardsListingWizardIdPicturesDelete', 'listingWizardId', listingWizardId)
            const localVarPath = `/api/v1/operator/listings/wizards/{listingWizardId}/pictures`
                .replace(`{${"listingWizardId"}}`, encodeURIComponent(String(listingWizardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pictureGuid !== undefined) {
                localVarQueryParameter['pictureGuid'] = pictureGuid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} listingWizardId 
         * @param {ListingWizardPictureType} [pictureType] 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsWizardsListingWizardIdPicturesPost: async (listingWizardId: number, pictureType?: ListingWizardPictureType, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listingWizardId' is not null or undefined
            assertParamExists('apiV1OperatorListingsWizardsListingWizardIdPicturesPost', 'listingWizardId', listingWizardId)
            const localVarPath = `/api/v1/operator/listings/wizards/{listingWizardId}/pictures`
                .replace(`{${"listingWizardId"}}`, encodeURIComponent(String(listingWizardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pictureType !== undefined) {
                localVarQueryParameter['pictureType'] = pictureType;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} listingWizardId 
         * @param {UpdateListingWizardPricingBasePriceCommand} [updateListingWizardPricingBasePriceCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsWizardsListingWizardIdPricingPut: async (listingWizardId: number, updateListingWizardPricingBasePriceCommand?: UpdateListingWizardPricingBasePriceCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listingWizardId' is not null or undefined
            assertParamExists('apiV1OperatorListingsWizardsListingWizardIdPricingPut', 'listingWizardId', listingWizardId)
            const localVarPath = `/api/v1/operator/listings/wizards/{listingWizardId}/pricing`
                .replace(`{${"listingWizardId"}}`, encodeURIComponent(String(listingWizardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateListingWizardPricingBasePriceCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} listingWizardId 
         * @param {UpdateListingWizardRulesAndPoliciesCommand} [updateListingWizardRulesAndPoliciesCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsWizardsListingWizardIdRulesAndPoliciesPut: async (listingWizardId: number, updateListingWizardRulesAndPoliciesCommand?: UpdateListingWizardRulesAndPoliciesCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listingWizardId' is not null or undefined
            assertParamExists('apiV1OperatorListingsWizardsListingWizardIdRulesAndPoliciesPut', 'listingWizardId', listingWizardId)
            const localVarPath = `/api/v1/operator/listings/wizards/{listingWizardId}/rules-and-policies`
                .replace(`{${"listingWizardId"}}`, encodeURIComponent(String(listingWizardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateListingWizardRulesAndPoliciesCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} listingWizardId 
         * @param {UpdateListingWizardAgreementsAndSubmitCommand} [updateListingWizardAgreementsAndSubmitCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsWizardsListingWizardIdSummaryAgreementsPut: async (listingWizardId: number, updateListingWizardAgreementsAndSubmitCommand?: UpdateListingWizardAgreementsAndSubmitCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listingWizardId' is not null or undefined
            assertParamExists('apiV1OperatorListingsWizardsListingWizardIdSummaryAgreementsPut', 'listingWizardId', listingWizardId)
            const localVarPath = `/api/v1/operator/listings/wizards/{listingWizardId}/summary/agreements`
                .replace(`{${"listingWizardId"}}`, encodeURIComponent(String(listingWizardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateListingWizardAgreementsAndSubmitCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} listingWizardId 
         * @param {UpdateListingWizardSummaryNotesCommand} [updateListingWizardSummaryNotesCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsWizardsListingWizardIdSummaryRecapPut: async (listingWizardId: number, updateListingWizardSummaryNotesCommand?: UpdateListingWizardSummaryNotesCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listingWizardId' is not null or undefined
            assertParamExists('apiV1OperatorListingsWizardsListingWizardIdSummaryRecapPut', 'listingWizardId', listingWizardId)
            const localVarPath = `/api/v1/operator/listings/wizards/{listingWizardId}/summary/recap`
                .replace(`{${"listingWizardId"}}`, encodeURIComponent(String(listingWizardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateListingWizardSummaryNotesCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} listingWizardId 
         * @param {UpdateListingWizardVehicleDetailsAmenitiesCommand} [updateListingWizardVehicleDetailsAmenitiesCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsWizardsListingWizardIdVehicleDetailsAmenitiesPut: async (listingWizardId: number, updateListingWizardVehicleDetailsAmenitiesCommand?: UpdateListingWizardVehicleDetailsAmenitiesCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listingWizardId' is not null or undefined
            assertParamExists('apiV1OperatorListingsWizardsListingWizardIdVehicleDetailsAmenitiesPut', 'listingWizardId', listingWizardId)
            const localVarPath = `/api/v1/operator/listings/wizards/{listingWizardId}/vehicle-details/amenities`
                .replace(`{${"listingWizardId"}}`, encodeURIComponent(String(listingWizardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateListingWizardVehicleDetailsAmenitiesCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} listingWizardId 
         * @param {UpdateListingWizardVehicleDetailsManufacturerAndModelCommand} [updateListingWizardVehicleDetailsManufacturerAndModelCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsWizardsListingWizardIdVehicleDetailsManufacturerAndModelPut: async (listingWizardId: number, updateListingWizardVehicleDetailsManufacturerAndModelCommand?: UpdateListingWizardVehicleDetailsManufacturerAndModelCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listingWizardId' is not null or undefined
            assertParamExists('apiV1OperatorListingsWizardsListingWizardIdVehicleDetailsManufacturerAndModelPut', 'listingWizardId', listingWizardId)
            const localVarPath = `/api/v1/operator/listings/wizards/{listingWizardId}/vehicle-details/manufacturer-and-model`
                .replace(`{${"listingWizardId"}}`, encodeURIComponent(String(listingWizardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateListingWizardVehicleDetailsManufacturerAndModelCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} listingWizardId 
         * @param {UpdateListingWizardVehicleDetailsOtherModelCommand} [updateListingWizardVehicleDetailsOtherModelCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsWizardsListingWizardIdVehicleDetailsOtherPut: async (listingWizardId: number, updateListingWizardVehicleDetailsOtherModelCommand?: UpdateListingWizardVehicleDetailsOtherModelCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listingWizardId' is not null or undefined
            assertParamExists('apiV1OperatorListingsWizardsListingWizardIdVehicleDetailsOtherPut', 'listingWizardId', listingWizardId)
            const localVarPath = `/api/v1/operator/listings/wizards/{listingWizardId}/vehicle-details/other`
                .replace(`{${"listingWizardId"}}`, encodeURIComponent(String(listingWizardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateListingWizardVehicleDetailsOtherModelCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} listingWizardId 
         * @param {UpdateListingWizardVehicleDetailsTransmissionAndEngineCommand} [updateListingWizardVehicleDetailsTransmissionAndEngineCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsWizardsListingWizardIdVehicleDetailsTransmissionAndEnginePut: async (listingWizardId: number, updateListingWizardVehicleDetailsTransmissionAndEngineCommand?: UpdateListingWizardVehicleDetailsTransmissionAndEngineCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listingWizardId' is not null or undefined
            assertParamExists('apiV1OperatorListingsWizardsListingWizardIdVehicleDetailsTransmissionAndEnginePut', 'listingWizardId', listingWizardId)
            const localVarPath = `/api/v1/operator/listings/wizards/{listingWizardId}/vehicle-details/transmission-and-engine`
                .replace(`{${"listingWizardId"}}`, encodeURIComponent(String(listingWizardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateListingWizardVehicleDetailsTransmissionAndEngineCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateOperatorFromListingWizardCommand} [createOperatorFromListingWizardCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsWizardsOperatorPost: async (createOperatorFromListingWizardCommand?: CreateOperatorFromListingWizardCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/operator/listings/wizards/operator`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOperatorFromListingWizardCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsWizardsPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/operator/listings/wizards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ListingWizardOperatorApi - functional programming interface
 * @export
 */
export const ListingWizardOperatorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ListingWizardOperatorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [manufacturerGuid] 
         * @param {string} [modelGuid] 
         * @param {number} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorListingsRecommendedBasePriceGet(manufacturerGuid?: string, modelGuid?: string, year?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListingWizardRecommendedBasePriceQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorListingsRecommendedBasePriceGet(manufacturerGuid, modelGuid, year, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [manufacturerGuid] 
         * @param {string} [modelGuid] 
         * @param {number} [year] 
         * @param {number} [basePrice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorListingsRecommendedOtherPricesGet(manufacturerGuid?: string, modelGuid?: string, year?: number, basePrice?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListingWizardRecommendedOtherPricesQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorListingsRecommendedOtherPricesGet(manufacturerGuid, modelGuid, year, basePrice, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [pageOffset] The offset from where the pagination starts.
         * @param {number} [pageSize] The size of page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorListingsWizardsDeclinedGet(pageOffset?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOperatorListingWizardsQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorListingsWizardsDeclinedGet(pageOffset, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [pageOffset] The offset from where the pagination starts.
         * @param {number} [pageSize] The size of page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorListingsWizardsGet(pageOffset?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOperatorListingWizardsQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorListingsWizardsGet(pageOffset, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [pageOffset] The offset from where the pagination starts.
         * @param {number} [pageSize] The size of page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorListingsWizardsInprogressGet(pageOffset?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOperatorListingWizardsQueryResultPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorListingsWizardsInprogressGet(pageOffset, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the HTML template of the mediation contract.
         * @param {string} listingWizardGuid 
         * @param {string} [accessToken] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async apiV1OperatorListingsWizardsListingWizardGuidMediationContractHtmlGet(listingWizardGuid: string, accessToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorListingsWizardsListingWizardGuidMediationContractHtmlGet(listingWizardGuid, accessToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} listingWizardId 
         * @param {ListingWizardState} [currentUiState] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorListingsWizardsListingWizardIdGet(listingWizardId: number, currentUiState?: ListingWizardState, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListingWizardQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorListingsWizardsListingWizardIdGet(listingWizardId, currentUiState, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the PDF file of mediation contract.
         * @param {number} listingWizardId The listing wizard identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorListingsWizardsListingWizardIdMediationContractPdfGet(listingWizardId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorListingsWizardsListingWizardIdMediationContractPdfGet(listingWizardId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} listingWizardId 
         * @param {UpdateListingWizardPickUpLocationCommand} [updateListingWizardPickUpLocationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorListingsWizardsListingWizardIdPickUpLocationPut(listingWizardId: number, updateListingWizardPickUpLocationCommand?: UpdateListingWizardPickUpLocationCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorListingsWizardsListingWizardIdPickUpLocationPut(listingWizardId, updateListingWizardPickUpLocationCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} listingWizardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorListingsWizardsListingWizardIdPicturesConfirmPut(listingWizardId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorListingsWizardsListingWizardIdPicturesConfirmPut(listingWizardId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} listingWizardId 
         * @param {string} [pictureGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorListingsWizardsListingWizardIdPicturesDelete(listingWizardId: number, pictureGuid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorListingsWizardsListingWizardIdPicturesDelete(listingWizardId, pictureGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} listingWizardId 
         * @param {ListingWizardPictureType} [pictureType] 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorListingsWizardsListingWizardIdPicturesPost(listingWizardId: number, pictureType?: ListingWizardPictureType, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorListingsWizardsListingWizardIdPicturesPost(listingWizardId, pictureType, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} listingWizardId 
         * @param {UpdateListingWizardPricingBasePriceCommand} [updateListingWizardPricingBasePriceCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorListingsWizardsListingWizardIdPricingPut(listingWizardId: number, updateListingWizardPricingBasePriceCommand?: UpdateListingWizardPricingBasePriceCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorListingsWizardsListingWizardIdPricingPut(listingWizardId, updateListingWizardPricingBasePriceCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} listingWizardId 
         * @param {UpdateListingWizardRulesAndPoliciesCommand} [updateListingWizardRulesAndPoliciesCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorListingsWizardsListingWizardIdRulesAndPoliciesPut(listingWizardId: number, updateListingWizardRulesAndPoliciesCommand?: UpdateListingWizardRulesAndPoliciesCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorListingsWizardsListingWizardIdRulesAndPoliciesPut(listingWizardId, updateListingWizardRulesAndPoliciesCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} listingWizardId 
         * @param {UpdateListingWizardAgreementsAndSubmitCommand} [updateListingWizardAgreementsAndSubmitCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorListingsWizardsListingWizardIdSummaryAgreementsPut(listingWizardId: number, updateListingWizardAgreementsAndSubmitCommand?: UpdateListingWizardAgreementsAndSubmitCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorListingsWizardsListingWizardIdSummaryAgreementsPut(listingWizardId, updateListingWizardAgreementsAndSubmitCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} listingWizardId 
         * @param {UpdateListingWizardSummaryNotesCommand} [updateListingWizardSummaryNotesCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorListingsWizardsListingWizardIdSummaryRecapPut(listingWizardId: number, updateListingWizardSummaryNotesCommand?: UpdateListingWizardSummaryNotesCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorListingsWizardsListingWizardIdSummaryRecapPut(listingWizardId, updateListingWizardSummaryNotesCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} listingWizardId 
         * @param {UpdateListingWizardVehicleDetailsAmenitiesCommand} [updateListingWizardVehicleDetailsAmenitiesCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorListingsWizardsListingWizardIdVehicleDetailsAmenitiesPut(listingWizardId: number, updateListingWizardVehicleDetailsAmenitiesCommand?: UpdateListingWizardVehicleDetailsAmenitiesCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorListingsWizardsListingWizardIdVehicleDetailsAmenitiesPut(listingWizardId, updateListingWizardVehicleDetailsAmenitiesCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} listingWizardId 
         * @param {UpdateListingWizardVehicleDetailsManufacturerAndModelCommand} [updateListingWizardVehicleDetailsManufacturerAndModelCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorListingsWizardsListingWizardIdVehicleDetailsManufacturerAndModelPut(listingWizardId: number, updateListingWizardVehicleDetailsManufacturerAndModelCommand?: UpdateListingWizardVehicleDetailsManufacturerAndModelCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateListingWizardVehicleDetailsManufacturerAndModelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorListingsWizardsListingWizardIdVehicleDetailsManufacturerAndModelPut(listingWizardId, updateListingWizardVehicleDetailsManufacturerAndModelCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} listingWizardId 
         * @param {UpdateListingWizardVehicleDetailsOtherModelCommand} [updateListingWizardVehicleDetailsOtherModelCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorListingsWizardsListingWizardIdVehicleDetailsOtherPut(listingWizardId: number, updateListingWizardVehicleDetailsOtherModelCommand?: UpdateListingWizardVehicleDetailsOtherModelCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorListingsWizardsListingWizardIdVehicleDetailsOtherPut(listingWizardId, updateListingWizardVehicleDetailsOtherModelCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} listingWizardId 
         * @param {UpdateListingWizardVehicleDetailsTransmissionAndEngineCommand} [updateListingWizardVehicleDetailsTransmissionAndEngineCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorListingsWizardsListingWizardIdVehicleDetailsTransmissionAndEnginePut(listingWizardId: number, updateListingWizardVehicleDetailsTransmissionAndEngineCommand?: UpdateListingWizardVehicleDetailsTransmissionAndEngineCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateListingWizardVehicleDetailsTransmissionAndEngineResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorListingsWizardsListingWizardIdVehicleDetailsTransmissionAndEnginePut(listingWizardId, updateListingWizardVehicleDetailsTransmissionAndEngineCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateOperatorFromListingWizardCommand} [createOperatorFromListingWizardCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorListingsWizardsOperatorPost(createOperatorFromListingWizardCommand?: CreateOperatorFromListingWizardCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorListingsWizardsOperatorPost(createOperatorFromListingWizardCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperatorListingsWizardsPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperatorListingsWizardsPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ListingWizardOperatorApi - factory interface
 * @export
 */
export const ListingWizardOperatorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ListingWizardOperatorApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [manufacturerGuid] 
         * @param {string} [modelGuid] 
         * @param {number} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsRecommendedBasePriceGet(manufacturerGuid?: string, modelGuid?: string, year?: number, options?: any): AxiosPromise<ListingWizardRecommendedBasePriceQueryResult> {
            return localVarFp.apiV1OperatorListingsRecommendedBasePriceGet(manufacturerGuid, modelGuid, year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [manufacturerGuid] 
         * @param {string} [modelGuid] 
         * @param {number} [year] 
         * @param {number} [basePrice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsRecommendedOtherPricesGet(manufacturerGuid?: string, modelGuid?: string, year?: number, basePrice?: number, options?: any): AxiosPromise<ListingWizardRecommendedOtherPricesQueryResult> {
            return localVarFp.apiV1OperatorListingsRecommendedOtherPricesGet(manufacturerGuid, modelGuid, year, basePrice, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pageOffset] The offset from where the pagination starts.
         * @param {number} [pageSize] The size of page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsWizardsDeclinedGet(pageOffset?: number, pageSize?: number, options?: any): AxiosPromise<GetOperatorListingWizardsQueryResultPaginatedResult> {
            return localVarFp.apiV1OperatorListingsWizardsDeclinedGet(pageOffset, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pageOffset] The offset from where the pagination starts.
         * @param {number} [pageSize] The size of page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsWizardsGet(pageOffset?: number, pageSize?: number, options?: any): AxiosPromise<GetOperatorListingWizardsQueryResultPaginatedResult> {
            return localVarFp.apiV1OperatorListingsWizardsGet(pageOffset, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pageOffset] The offset from where the pagination starts.
         * @param {number} [pageSize] The size of page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsWizardsInprogressGet(pageOffset?: number, pageSize?: number, options?: any): AxiosPromise<GetOperatorListingWizardsQueryResultPaginatedResult> {
            return localVarFp.apiV1OperatorListingsWizardsInprogressGet(pageOffset, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the HTML template of the mediation contract.
         * @param {string} listingWizardGuid 
         * @param {string} [accessToken] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        apiV1OperatorListingsWizardsListingWizardGuidMediationContractHtmlGet(listingWizardGuid: string, accessToken?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorListingsWizardsListingWizardGuidMediationContractHtmlGet(listingWizardGuid, accessToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} listingWizardId 
         * @param {ListingWizardState} [currentUiState] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsWizardsListingWizardIdGet(listingWizardId: number, currentUiState?: ListingWizardState, options?: any): AxiosPromise<ListingWizardQueryResult> {
            return localVarFp.apiV1OperatorListingsWizardsListingWizardIdGet(listingWizardId, currentUiState, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the PDF file of mediation contract.
         * @param {number} listingWizardId The listing wizard identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsWizardsListingWizardIdMediationContractPdfGet(listingWizardId: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorListingsWizardsListingWizardIdMediationContractPdfGet(listingWizardId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} listingWizardId 
         * @param {UpdateListingWizardPickUpLocationCommand} [updateListingWizardPickUpLocationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsWizardsListingWizardIdPickUpLocationPut(listingWizardId: number, updateListingWizardPickUpLocationCommand?: UpdateListingWizardPickUpLocationCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorListingsWizardsListingWizardIdPickUpLocationPut(listingWizardId, updateListingWizardPickUpLocationCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} listingWizardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsWizardsListingWizardIdPicturesConfirmPut(listingWizardId: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorListingsWizardsListingWizardIdPicturesConfirmPut(listingWizardId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} listingWizardId 
         * @param {string} [pictureGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsWizardsListingWizardIdPicturesDelete(listingWizardId: number, pictureGuid?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorListingsWizardsListingWizardIdPicturesDelete(listingWizardId, pictureGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} listingWizardId 
         * @param {ListingWizardPictureType} [pictureType] 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsWizardsListingWizardIdPicturesPost(listingWizardId: number, pictureType?: ListingWizardPictureType, file?: File, options?: any): AxiosPromise<string> {
            return localVarFp.apiV1OperatorListingsWizardsListingWizardIdPicturesPost(listingWizardId, pictureType, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} listingWizardId 
         * @param {UpdateListingWizardPricingBasePriceCommand} [updateListingWizardPricingBasePriceCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsWizardsListingWizardIdPricingPut(listingWizardId: number, updateListingWizardPricingBasePriceCommand?: UpdateListingWizardPricingBasePriceCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorListingsWizardsListingWizardIdPricingPut(listingWizardId, updateListingWizardPricingBasePriceCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} listingWizardId 
         * @param {UpdateListingWizardRulesAndPoliciesCommand} [updateListingWizardRulesAndPoliciesCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsWizardsListingWizardIdRulesAndPoliciesPut(listingWizardId: number, updateListingWizardRulesAndPoliciesCommand?: UpdateListingWizardRulesAndPoliciesCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorListingsWizardsListingWizardIdRulesAndPoliciesPut(listingWizardId, updateListingWizardRulesAndPoliciesCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} listingWizardId 
         * @param {UpdateListingWizardAgreementsAndSubmitCommand} [updateListingWizardAgreementsAndSubmitCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsWizardsListingWizardIdSummaryAgreementsPut(listingWizardId: number, updateListingWizardAgreementsAndSubmitCommand?: UpdateListingWizardAgreementsAndSubmitCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorListingsWizardsListingWizardIdSummaryAgreementsPut(listingWizardId, updateListingWizardAgreementsAndSubmitCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} listingWizardId 
         * @param {UpdateListingWizardSummaryNotesCommand} [updateListingWizardSummaryNotesCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsWizardsListingWizardIdSummaryRecapPut(listingWizardId: number, updateListingWizardSummaryNotesCommand?: UpdateListingWizardSummaryNotesCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorListingsWizardsListingWizardIdSummaryRecapPut(listingWizardId, updateListingWizardSummaryNotesCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} listingWizardId 
         * @param {UpdateListingWizardVehicleDetailsAmenitiesCommand} [updateListingWizardVehicleDetailsAmenitiesCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsWizardsListingWizardIdVehicleDetailsAmenitiesPut(listingWizardId: number, updateListingWizardVehicleDetailsAmenitiesCommand?: UpdateListingWizardVehicleDetailsAmenitiesCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorListingsWizardsListingWizardIdVehicleDetailsAmenitiesPut(listingWizardId, updateListingWizardVehicleDetailsAmenitiesCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} listingWizardId 
         * @param {UpdateListingWizardVehicleDetailsManufacturerAndModelCommand} [updateListingWizardVehicleDetailsManufacturerAndModelCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsWizardsListingWizardIdVehicleDetailsManufacturerAndModelPut(listingWizardId: number, updateListingWizardVehicleDetailsManufacturerAndModelCommand?: UpdateListingWizardVehicleDetailsManufacturerAndModelCommand, options?: any): AxiosPromise<UpdateListingWizardVehicleDetailsManufacturerAndModelResponse> {
            return localVarFp.apiV1OperatorListingsWizardsListingWizardIdVehicleDetailsManufacturerAndModelPut(listingWizardId, updateListingWizardVehicleDetailsManufacturerAndModelCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} listingWizardId 
         * @param {UpdateListingWizardVehicleDetailsOtherModelCommand} [updateListingWizardVehicleDetailsOtherModelCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsWizardsListingWizardIdVehicleDetailsOtherPut(listingWizardId: number, updateListingWizardVehicleDetailsOtherModelCommand?: UpdateListingWizardVehicleDetailsOtherModelCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorListingsWizardsListingWizardIdVehicleDetailsOtherPut(listingWizardId, updateListingWizardVehicleDetailsOtherModelCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} listingWizardId 
         * @param {UpdateListingWizardVehicleDetailsTransmissionAndEngineCommand} [updateListingWizardVehicleDetailsTransmissionAndEngineCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsWizardsListingWizardIdVehicleDetailsTransmissionAndEnginePut(listingWizardId: number, updateListingWizardVehicleDetailsTransmissionAndEngineCommand?: UpdateListingWizardVehicleDetailsTransmissionAndEngineCommand, options?: any): AxiosPromise<UpdateListingWizardVehicleDetailsTransmissionAndEngineResponse> {
            return localVarFp.apiV1OperatorListingsWizardsListingWizardIdVehicleDetailsTransmissionAndEnginePut(listingWizardId, updateListingWizardVehicleDetailsTransmissionAndEngineCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateOperatorFromListingWizardCommand} [createOperatorFromListingWizardCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsWizardsOperatorPost(createOperatorFromListingWizardCommand?: CreateOperatorFromListingWizardCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorListingsWizardsOperatorPost(createOperatorFromListingWizardCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperatorListingsWizardsPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1OperatorListingsWizardsPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ListingWizardOperatorApi - interface
 * @export
 * @interface ListingWizardOperatorApi
 */
export interface ListingWizardOperatorApiInterface {
    /**
     * 
     * @param {string} [manufacturerGuid] 
     * @param {string} [modelGuid] 
     * @param {number} [year] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingWizardOperatorApiInterface
     */
    apiV1OperatorListingsRecommendedBasePriceGet(manufacturerGuid?: string, modelGuid?: string, year?: number, options?: AxiosRequestConfig): AxiosPromise<ListingWizardRecommendedBasePriceQueryResult>;

    /**
     * 
     * @param {string} [manufacturerGuid] 
     * @param {string} [modelGuid] 
     * @param {number} [year] 
     * @param {number} [basePrice] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingWizardOperatorApiInterface
     */
    apiV1OperatorListingsRecommendedOtherPricesGet(manufacturerGuid?: string, modelGuid?: string, year?: number, basePrice?: number, options?: AxiosRequestConfig): AxiosPromise<ListingWizardRecommendedOtherPricesQueryResult>;

    /**
     * 
     * @param {number} [pageOffset] The offset from where the pagination starts.
     * @param {number} [pageSize] The size of page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingWizardOperatorApiInterface
     */
    apiV1OperatorListingsWizardsDeclinedGet(pageOffset?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<GetOperatorListingWizardsQueryResultPaginatedResult>;

    /**
     * 
     * @param {number} [pageOffset] The offset from where the pagination starts.
     * @param {number} [pageSize] The size of page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingWizardOperatorApiInterface
     */
    apiV1OperatorListingsWizardsGet(pageOffset?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<GetOperatorListingWizardsQueryResultPaginatedResult>;

    /**
     * 
     * @param {number} [pageOffset] The offset from where the pagination starts.
     * @param {number} [pageSize] The size of page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingWizardOperatorApiInterface
     */
    apiV1OperatorListingsWizardsInprogressGet(pageOffset?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<GetOperatorListingWizardsQueryResultPaginatedResult>;

    /**
     * 
     * @summary Gets the HTML template of the mediation contract.
     * @param {string} listingWizardGuid 
     * @param {string} [accessToken] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ListingWizardOperatorApiInterface
     */
    apiV1OperatorListingsWizardsListingWizardGuidMediationContractHtmlGet(listingWizardGuid: string, accessToken?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} listingWizardId 
     * @param {ListingWizardState} [currentUiState] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingWizardOperatorApiInterface
     */
    apiV1OperatorListingsWizardsListingWizardIdGet(listingWizardId: number, currentUiState?: ListingWizardState, options?: AxiosRequestConfig): AxiosPromise<ListingWizardQueryResult>;

    /**
     * 
     * @summary Gets the PDF file of mediation contract.
     * @param {number} listingWizardId The listing wizard identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingWizardOperatorApiInterface
     */
    apiV1OperatorListingsWizardsListingWizardIdMediationContractPdfGet(listingWizardId: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} listingWizardId 
     * @param {UpdateListingWizardPickUpLocationCommand} [updateListingWizardPickUpLocationCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingWizardOperatorApiInterface
     */
    apiV1OperatorListingsWizardsListingWizardIdPickUpLocationPut(listingWizardId: number, updateListingWizardPickUpLocationCommand?: UpdateListingWizardPickUpLocationCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} listingWizardId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingWizardOperatorApiInterface
     */
    apiV1OperatorListingsWizardsListingWizardIdPicturesConfirmPut(listingWizardId: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} listingWizardId 
     * @param {string} [pictureGuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingWizardOperatorApiInterface
     */
    apiV1OperatorListingsWizardsListingWizardIdPicturesDelete(listingWizardId: number, pictureGuid?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} listingWizardId 
     * @param {ListingWizardPictureType} [pictureType] 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingWizardOperatorApiInterface
     */
    apiV1OperatorListingsWizardsListingWizardIdPicturesPost(listingWizardId: number, pictureType?: ListingWizardPictureType, file?: File, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {number} listingWizardId 
     * @param {UpdateListingWizardPricingBasePriceCommand} [updateListingWizardPricingBasePriceCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingWizardOperatorApiInterface
     */
    apiV1OperatorListingsWizardsListingWizardIdPricingPut(listingWizardId: number, updateListingWizardPricingBasePriceCommand?: UpdateListingWizardPricingBasePriceCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} listingWizardId 
     * @param {UpdateListingWizardRulesAndPoliciesCommand} [updateListingWizardRulesAndPoliciesCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingWizardOperatorApiInterface
     */
    apiV1OperatorListingsWizardsListingWizardIdRulesAndPoliciesPut(listingWizardId: number, updateListingWizardRulesAndPoliciesCommand?: UpdateListingWizardRulesAndPoliciesCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} listingWizardId 
     * @param {UpdateListingWizardAgreementsAndSubmitCommand} [updateListingWizardAgreementsAndSubmitCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingWizardOperatorApiInterface
     */
    apiV1OperatorListingsWizardsListingWizardIdSummaryAgreementsPut(listingWizardId: number, updateListingWizardAgreementsAndSubmitCommand?: UpdateListingWizardAgreementsAndSubmitCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} listingWizardId 
     * @param {UpdateListingWizardSummaryNotesCommand} [updateListingWizardSummaryNotesCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingWizardOperatorApiInterface
     */
    apiV1OperatorListingsWizardsListingWizardIdSummaryRecapPut(listingWizardId: number, updateListingWizardSummaryNotesCommand?: UpdateListingWizardSummaryNotesCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} listingWizardId 
     * @param {UpdateListingWizardVehicleDetailsAmenitiesCommand} [updateListingWizardVehicleDetailsAmenitiesCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingWizardOperatorApiInterface
     */
    apiV1OperatorListingsWizardsListingWizardIdVehicleDetailsAmenitiesPut(listingWizardId: number, updateListingWizardVehicleDetailsAmenitiesCommand?: UpdateListingWizardVehicleDetailsAmenitiesCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} listingWizardId 
     * @param {UpdateListingWizardVehicleDetailsManufacturerAndModelCommand} [updateListingWizardVehicleDetailsManufacturerAndModelCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingWizardOperatorApiInterface
     */
    apiV1OperatorListingsWizardsListingWizardIdVehicleDetailsManufacturerAndModelPut(listingWizardId: number, updateListingWizardVehicleDetailsManufacturerAndModelCommand?: UpdateListingWizardVehicleDetailsManufacturerAndModelCommand, options?: AxiosRequestConfig): AxiosPromise<UpdateListingWizardVehicleDetailsManufacturerAndModelResponse>;

    /**
     * 
     * @param {number} listingWizardId 
     * @param {UpdateListingWizardVehicleDetailsOtherModelCommand} [updateListingWizardVehicleDetailsOtherModelCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingWizardOperatorApiInterface
     */
    apiV1OperatorListingsWizardsListingWizardIdVehicleDetailsOtherPut(listingWizardId: number, updateListingWizardVehicleDetailsOtherModelCommand?: UpdateListingWizardVehicleDetailsOtherModelCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} listingWizardId 
     * @param {UpdateListingWizardVehicleDetailsTransmissionAndEngineCommand} [updateListingWizardVehicleDetailsTransmissionAndEngineCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingWizardOperatorApiInterface
     */
    apiV1OperatorListingsWizardsListingWizardIdVehicleDetailsTransmissionAndEnginePut(listingWizardId: number, updateListingWizardVehicleDetailsTransmissionAndEngineCommand?: UpdateListingWizardVehicleDetailsTransmissionAndEngineCommand, options?: AxiosRequestConfig): AxiosPromise<UpdateListingWizardVehicleDetailsTransmissionAndEngineResponse>;

    /**
     * 
     * @param {CreateOperatorFromListingWizardCommand} [createOperatorFromListingWizardCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingWizardOperatorApiInterface
     */
    apiV1OperatorListingsWizardsOperatorPost(createOperatorFromListingWizardCommand?: CreateOperatorFromListingWizardCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingWizardOperatorApiInterface
     */
    apiV1OperatorListingsWizardsPost(options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * ListingWizardOperatorApi - object-oriented interface
 * @export
 * @class ListingWizardOperatorApi
 * @extends {BaseAPI}
 */
export class ListingWizardOperatorApi extends BaseAPI implements ListingWizardOperatorApiInterface {
    /**
     * 
     * @param {string} [manufacturerGuid] 
     * @param {string} [modelGuid] 
     * @param {number} [year] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingWizardOperatorApi
     */
    public apiV1OperatorListingsRecommendedBasePriceGet(manufacturerGuid?: string, modelGuid?: string, year?: number, options?: AxiosRequestConfig) {
        return ListingWizardOperatorApiFp(this.configuration).apiV1OperatorListingsRecommendedBasePriceGet(manufacturerGuid, modelGuid, year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [manufacturerGuid] 
     * @param {string} [modelGuid] 
     * @param {number} [year] 
     * @param {number} [basePrice] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingWizardOperatorApi
     */
    public apiV1OperatorListingsRecommendedOtherPricesGet(manufacturerGuid?: string, modelGuid?: string, year?: number, basePrice?: number, options?: AxiosRequestConfig) {
        return ListingWizardOperatorApiFp(this.configuration).apiV1OperatorListingsRecommendedOtherPricesGet(manufacturerGuid, modelGuid, year, basePrice, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [pageOffset] The offset from where the pagination starts.
     * @param {number} [pageSize] The size of page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingWizardOperatorApi
     */
    public apiV1OperatorListingsWizardsDeclinedGet(pageOffset?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return ListingWizardOperatorApiFp(this.configuration).apiV1OperatorListingsWizardsDeclinedGet(pageOffset, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [pageOffset] The offset from where the pagination starts.
     * @param {number} [pageSize] The size of page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingWizardOperatorApi
     */
    public apiV1OperatorListingsWizardsGet(pageOffset?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return ListingWizardOperatorApiFp(this.configuration).apiV1OperatorListingsWizardsGet(pageOffset, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [pageOffset] The offset from where the pagination starts.
     * @param {number} [pageSize] The size of page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingWizardOperatorApi
     */
    public apiV1OperatorListingsWizardsInprogressGet(pageOffset?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return ListingWizardOperatorApiFp(this.configuration).apiV1OperatorListingsWizardsInprogressGet(pageOffset, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the HTML template of the mediation contract.
     * @param {string} listingWizardGuid 
     * @param {string} [accessToken] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ListingWizardOperatorApi
     */
    public apiV1OperatorListingsWizardsListingWizardGuidMediationContractHtmlGet(listingWizardGuid: string, accessToken?: string, options?: AxiosRequestConfig) {
        return ListingWizardOperatorApiFp(this.configuration).apiV1OperatorListingsWizardsListingWizardGuidMediationContractHtmlGet(listingWizardGuid, accessToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} listingWizardId 
     * @param {ListingWizardState} [currentUiState] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingWizardOperatorApi
     */
    public apiV1OperatorListingsWizardsListingWizardIdGet(listingWizardId: number, currentUiState?: ListingWizardState, options?: AxiosRequestConfig) {
        return ListingWizardOperatorApiFp(this.configuration).apiV1OperatorListingsWizardsListingWizardIdGet(listingWizardId, currentUiState, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the PDF file of mediation contract.
     * @param {number} listingWizardId The listing wizard identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingWizardOperatorApi
     */
    public apiV1OperatorListingsWizardsListingWizardIdMediationContractPdfGet(listingWizardId: number, options?: AxiosRequestConfig) {
        return ListingWizardOperatorApiFp(this.configuration).apiV1OperatorListingsWizardsListingWizardIdMediationContractPdfGet(listingWizardId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} listingWizardId 
     * @param {UpdateListingWizardPickUpLocationCommand} [updateListingWizardPickUpLocationCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingWizardOperatorApi
     */
    public apiV1OperatorListingsWizardsListingWizardIdPickUpLocationPut(listingWizardId: number, updateListingWizardPickUpLocationCommand?: UpdateListingWizardPickUpLocationCommand, options?: AxiosRequestConfig) {
        return ListingWizardOperatorApiFp(this.configuration).apiV1OperatorListingsWizardsListingWizardIdPickUpLocationPut(listingWizardId, updateListingWizardPickUpLocationCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} listingWizardId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingWizardOperatorApi
     */
    public apiV1OperatorListingsWizardsListingWizardIdPicturesConfirmPut(listingWizardId: number, options?: AxiosRequestConfig) {
        return ListingWizardOperatorApiFp(this.configuration).apiV1OperatorListingsWizardsListingWizardIdPicturesConfirmPut(listingWizardId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} listingWizardId 
     * @param {string} [pictureGuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingWizardOperatorApi
     */
    public apiV1OperatorListingsWizardsListingWizardIdPicturesDelete(listingWizardId: number, pictureGuid?: string, options?: AxiosRequestConfig) {
        return ListingWizardOperatorApiFp(this.configuration).apiV1OperatorListingsWizardsListingWizardIdPicturesDelete(listingWizardId, pictureGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} listingWizardId 
     * @param {ListingWizardPictureType} [pictureType] 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingWizardOperatorApi
     */
    public apiV1OperatorListingsWizardsListingWizardIdPicturesPost(listingWizardId: number, pictureType?: ListingWizardPictureType, file?: File, options?: AxiosRequestConfig) {
        return ListingWizardOperatorApiFp(this.configuration).apiV1OperatorListingsWizardsListingWizardIdPicturesPost(listingWizardId, pictureType, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} listingWizardId 
     * @param {UpdateListingWizardPricingBasePriceCommand} [updateListingWizardPricingBasePriceCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingWizardOperatorApi
     */
    public apiV1OperatorListingsWizardsListingWizardIdPricingPut(listingWizardId: number, updateListingWizardPricingBasePriceCommand?: UpdateListingWizardPricingBasePriceCommand, options?: AxiosRequestConfig) {
        return ListingWizardOperatorApiFp(this.configuration).apiV1OperatorListingsWizardsListingWizardIdPricingPut(listingWizardId, updateListingWizardPricingBasePriceCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} listingWizardId 
     * @param {UpdateListingWizardRulesAndPoliciesCommand} [updateListingWizardRulesAndPoliciesCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingWizardOperatorApi
     */
    public apiV1OperatorListingsWizardsListingWizardIdRulesAndPoliciesPut(listingWizardId: number, updateListingWizardRulesAndPoliciesCommand?: UpdateListingWizardRulesAndPoliciesCommand, options?: AxiosRequestConfig) {
        return ListingWizardOperatorApiFp(this.configuration).apiV1OperatorListingsWizardsListingWizardIdRulesAndPoliciesPut(listingWizardId, updateListingWizardRulesAndPoliciesCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} listingWizardId 
     * @param {UpdateListingWizardAgreementsAndSubmitCommand} [updateListingWizardAgreementsAndSubmitCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingWizardOperatorApi
     */
    public apiV1OperatorListingsWizardsListingWizardIdSummaryAgreementsPut(listingWizardId: number, updateListingWizardAgreementsAndSubmitCommand?: UpdateListingWizardAgreementsAndSubmitCommand, options?: AxiosRequestConfig) {
        return ListingWizardOperatorApiFp(this.configuration).apiV1OperatorListingsWizardsListingWizardIdSummaryAgreementsPut(listingWizardId, updateListingWizardAgreementsAndSubmitCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} listingWizardId 
     * @param {UpdateListingWizardSummaryNotesCommand} [updateListingWizardSummaryNotesCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingWizardOperatorApi
     */
    public apiV1OperatorListingsWizardsListingWizardIdSummaryRecapPut(listingWizardId: number, updateListingWizardSummaryNotesCommand?: UpdateListingWizardSummaryNotesCommand, options?: AxiosRequestConfig) {
        return ListingWizardOperatorApiFp(this.configuration).apiV1OperatorListingsWizardsListingWizardIdSummaryRecapPut(listingWizardId, updateListingWizardSummaryNotesCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} listingWizardId 
     * @param {UpdateListingWizardVehicleDetailsAmenitiesCommand} [updateListingWizardVehicleDetailsAmenitiesCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingWizardOperatorApi
     */
    public apiV1OperatorListingsWizardsListingWizardIdVehicleDetailsAmenitiesPut(listingWizardId: number, updateListingWizardVehicleDetailsAmenitiesCommand?: UpdateListingWizardVehicleDetailsAmenitiesCommand, options?: AxiosRequestConfig) {
        return ListingWizardOperatorApiFp(this.configuration).apiV1OperatorListingsWizardsListingWizardIdVehicleDetailsAmenitiesPut(listingWizardId, updateListingWizardVehicleDetailsAmenitiesCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} listingWizardId 
     * @param {UpdateListingWizardVehicleDetailsManufacturerAndModelCommand} [updateListingWizardVehicleDetailsManufacturerAndModelCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingWizardOperatorApi
     */
    public apiV1OperatorListingsWizardsListingWizardIdVehicleDetailsManufacturerAndModelPut(listingWizardId: number, updateListingWizardVehicleDetailsManufacturerAndModelCommand?: UpdateListingWizardVehicleDetailsManufacturerAndModelCommand, options?: AxiosRequestConfig) {
        return ListingWizardOperatorApiFp(this.configuration).apiV1OperatorListingsWizardsListingWizardIdVehicleDetailsManufacturerAndModelPut(listingWizardId, updateListingWizardVehicleDetailsManufacturerAndModelCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} listingWizardId 
     * @param {UpdateListingWizardVehicleDetailsOtherModelCommand} [updateListingWizardVehicleDetailsOtherModelCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingWizardOperatorApi
     */
    public apiV1OperatorListingsWizardsListingWizardIdVehicleDetailsOtherPut(listingWizardId: number, updateListingWizardVehicleDetailsOtherModelCommand?: UpdateListingWizardVehicleDetailsOtherModelCommand, options?: AxiosRequestConfig) {
        return ListingWizardOperatorApiFp(this.configuration).apiV1OperatorListingsWizardsListingWizardIdVehicleDetailsOtherPut(listingWizardId, updateListingWizardVehicleDetailsOtherModelCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} listingWizardId 
     * @param {UpdateListingWizardVehicleDetailsTransmissionAndEngineCommand} [updateListingWizardVehicleDetailsTransmissionAndEngineCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingWizardOperatorApi
     */
    public apiV1OperatorListingsWizardsListingWizardIdVehicleDetailsTransmissionAndEnginePut(listingWizardId: number, updateListingWizardVehicleDetailsTransmissionAndEngineCommand?: UpdateListingWizardVehicleDetailsTransmissionAndEngineCommand, options?: AxiosRequestConfig) {
        return ListingWizardOperatorApiFp(this.configuration).apiV1OperatorListingsWizardsListingWizardIdVehicleDetailsTransmissionAndEnginePut(listingWizardId, updateListingWizardVehicleDetailsTransmissionAndEngineCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateOperatorFromListingWizardCommand} [createOperatorFromListingWizardCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingWizardOperatorApi
     */
    public apiV1OperatorListingsWizardsOperatorPost(createOperatorFromListingWizardCommand?: CreateOperatorFromListingWizardCommand, options?: AxiosRequestConfig) {
        return ListingWizardOperatorApiFp(this.configuration).apiV1OperatorListingsWizardsOperatorPost(createOperatorFromListingWizardCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingWizardOperatorApi
     */
    public apiV1OperatorListingsWizardsPost(options?: AxiosRequestConfig) {
        return ListingWizardOperatorApiFp(this.configuration).apiV1OperatorListingsWizardsPost(options).then((request) => request(this.axios, this.basePath));
    }
}

