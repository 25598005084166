/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const DriversLicenseCategory = {
    NotSet: 'NotSet',
    B: 'B',
    C: 'C',
    D: 'D',
    BE: 'BE'
} as const;

export type DriversLicenseCategory = typeof DriversLicenseCategory[keyof typeof DriversLicenseCategory];



